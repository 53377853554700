import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

const OpenNav = () => {
  if (document.getElementById("mySidenav").style.width == "0px") {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginRight = "250px";
  } else {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
  }
};

const Header = (props) => {
  console.log("Header Props", props);

  let loggedin;
  if (props.isLoggedin) {
    loggedin = `<li className="nav-item"> <a className="nav-link" href="#">Logged In</a> </li>`;
  } else {
    loggedin = `<li className="nav-item"> <a className="nav-link" href="#">Logged Out</a> </li>`;
  }

  return (
    <header className="header body-pd" id="header">
      <nav className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="dropdown"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="#">
            Chunky Sports
          </a>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
                className="nav-item header_toggle"
                style={{ marginTop: "3px" }}
              >
                <i className="bx bx-menu" id="header-toggle"></i>
              </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/">
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <a className="nav-link" href="#">
                  {props.user.firstname} {props.user.lastname}
                </a>
              </li>

              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/login">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/signup">
                  Sign Up
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  {props.firstname} {props.lastname}
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </div>
              </li>
            </ul>
            <form className="d-flex">
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <button className="btn btn-outline-success" type="submit">
                Search
              </button>
            </form>{" "}
            <a className="nav-link" href="#">
              <span
                style={{ fontWeight: "bold", fontSize: "18px" }}
                onClick={OpenNav}
              >
                &#9776;{" "}
              </span>
            </a>{" "}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
