import React, { useState, useEffect, Fragment } from "react"


const Dashboard = (props) => {

  let loggedin
  if(props.isLoggedin)
  {
    loggedin = "Logged In"
  }
  else
  {
    loggedin = "Logged Out"
  }
  
  
  return (
    <div className="middle">
    <div className="dashboard">
      <h1>Dashboard</h1>
      <h1>Status: {loggedin}</h1>

          
    </div>   
    </div>
  )
}

export default Dashboard