import React, { useState, useEffect, Fragment } from "react"
import Collapsible from 'react-collapsible'
import styled from "styled-components"
import ChunkCard from "../ChunkCard/ChunkCard"
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import './Acc.css'


const ProfileImg = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 50%;
`


const Acc = () => {
  return (
    <Collapsible trigger="Teams">
    <div className="teams-div" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "3%"}}>
    <div><span><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />User Name</span>
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
    </div>
  </Collapsible>
)  
}

export default Acc