import React, { useState, useEffect, Fragment } from "react"
import "./Team.css"
import Mupdates from "../Mupdates/Mupdates"
import Status from "../Status/Status"
import Tnews from "../Tnews/Tnews"

const Team = () => {

  
  
  return (
    <div className="middle">
      <Mupdates/>
      <Status/>
      <Tnews/>
          
    </div>   
  )
}

export default Team