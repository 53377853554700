import React, { useState, useEffect, Fragment } from "react";
import {
  FaThumbsUp,
  FaHeart,
  FaSmile,
  FaAngry,
  FaRegGrinAlt, FaVideo, FaImage, FaRegImages, FaGrinAlt
} from "react-icons/fa";

const Lumpitems = (props) => {
  //console.log(params);

  return (
    <div key={props.id} className="newspost">
      <div className="teamheader">
        <div className="teamlogo">
          <img
            src={props.server + "/" + props.username + ".png"}
            alt={props.first + " " + props.last}
          />
        </div>
        <div className="teamname">{props.name + " - " + props.category}</div>
      </div>
      <div className="newsheader">
        <div className="newsheaderimg">
          <img src={props.image} alt={props.title} />
        </div>
        <div className="newsheadername">
          <a href={props.link} target="_blank">
            {props.title}
          </a>

          <div className="date">{props.created_at}</div>
        </div>
      </div>
      <div className="newsbody">
        <div className="newsbodytext">{props.description}</div>
        <div className="newsbodyimg">
          <a href={props.link} target="_blank">
            <img src={props.image} alt={props.title} />
          </a>
        </div>
      </div>
      <div className="comments">
      <div className="comment-info-box">
        <div className="comment-icons">
          <FaThumbsUp className="thumpup-icon" />
          <FaHeart className="heart-icon" />
          <FaSmile className="smile-icon" />
          <FaAngry className="angry-icon" />
        </div>
        <div className="comment-number">{Math.floor(Math.random() * 51)} Comments</div>
        <hr className="comment-divider"></hr>
      </div>
      
      <div className="comment-box">
        <div className="comment-buttons">
          <span className="comment-buttons">
            <FaVideo className="live" />
            <span className="text"> Live </span>
          </span>
          <span className="comment-buttons">
            <FaRegImages className="photo" />
            <span className="text"> Photo </span>
          </span>
          <span className="comment-buttons">
            <FaRegGrinAlt className="feeling" />
            <span className="text"> Feeling </span>
          </span>
        </div>
      </div>
      </div>

      <div>
        {props.youtube && (
          <form>
            <input type="hidden" value={props.id} id="post-id" />
            <button
              type="button"
              onClick={() => clickHandler(props.id)}
              className="btn btn-primary"
            >
              Ban Lump
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Lumpitems;
