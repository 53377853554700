import React, { useState, useEffect, Fragment } from "react"
import Accordian from "../Accordian/Accordian"
import "./Source.css"

const Source = () => {
  return (
    
    <div className="height-100" id="main">
      <div style={{marginTop: "65px"}}>&nbsp;</div>
      <div className="container">
       <Accordian/>
      </div>
    </div>
    


  )
  
}

export default Source