import React, { useState, useEffect, Fragment } from "react"
import Collapsible from 'react-collapsible'
import styled from "styled-components"
import ChunkCard from "../ChunkCard/ChunkCard"
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import './Accordian.css'


const ProfileImg = styled.img`
  height: 35px;
  width: 35px;
  border-radius: 50%;
`


const Accordian = () => {
  return (
    <Collapsible trigger="Teams">
    <div className="teams-div" style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: "3%"}}>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div>
      <ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />

      </div>
      <div>
      <ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />

      <div className="card">
  <div className="card-header">
    Quote
  </div>
  <div className="card-body">
    <blockquote className="blockquote mb-0">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
      <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
    </blockquote>
  </div>
</div>


      </div>
      <div>Four</div>

      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div>
      <ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>

      </div>
      <div>
      <ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />

      <div className="card">
  <div className="card-header">
    Quote
  </div>
  <div className="card-body">
    <blockquote className="blockquote mb-0">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p>
      <footer className="blockquote-footer">Someone famous in <cite title="Source Title">Source Title</cite></footer>
    </blockquote>
  </div>
</div>


      </div>
      <div>
      <ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>


      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
      <div><ProfileImg
                    src="https://rts-llc.com/me.jpg"
                    alt="logo"
                   />
                   <div className="chunkcard" >
        <LinkPreview url='https://washingtonfootballwire.usatoday.com/2022/01/25/washington-football-team-no-20-in-nfl-special-teams-rankings/'  descriptionLength="100"/>
        </div>
      </div>
    </div>
  </Collapsible>
)  
}

export default Accordian